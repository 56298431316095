@import "../stylesheets/base.css";
@import "../stylesheets/fonts.css";
@import "../stylesheets/components.css";
@import "../stylesheets/utilities.css";
@import "../stylesheets/pagy_custom_navigation.css";

.heading-gradient {
  @apply text-7xl my-8 lg:my-12 font-bold text-transparent
  bg-clip-text bg-gradient-to-br text-transparent
  bg-clip-text bg-gradient-to-br from-green-500
  to-blue-700 filter drop-shadow;
}