@font-face {
  font-family: 'Lato';
  src: font_url('fonts/Lato/Lato-Bold.woff2') format('woff2');
  font-weight: bold;
  font-display: fallback;
}

@font-face {
  font-family: 'Lato';
  src: font_url('fonts/Lato/Lato-Regular.woff2') format('woff2');
  font-weight: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Lato';
  src: font_url('fonts/Lato/Lato-Light.woff2') format('woff2');
  font-weight: 300;
  font-display: fallback;
}